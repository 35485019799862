import React, { useState, useEffect, useRef, useCallback } from "react";
import NotificationAlert from "react-notification-alert";

import { Button, Row, Col, Spinner, Input } from "reactstrap";

import CardDash from "components/Cards/CardDash";

import CardStore from "components/Cards/CardStore";
import api from "../../../services/api";
import { LinkStyled } from "../express/styles.js";
import { Checkbox } from "@material-ui/core";

function Stores() {
  const notificationAlert = useRef(null);
  const [showDeleted, setShowDeleted] = useState(false);

  const notify = (place, color, message) => {
    var options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>{message}</div>
        </div>
      ),
      type: color,
      icon: "now-ui-icons ui-1_bell-53",
      autoDismiss: 7,
    };
    notificationAlert.current.notificationAlert(options);
  };
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);

  const token = localStorage.getItem("@clube:token");
  let [stores, setStores] = useState([]);

  const getStores = useCallback(async () => {
    try {
      const response = await api.get("/store/all", {
        headers: { Authorization: `Bearer ${token}` },
      });

      setStores(response.data);
      setLoading(false);
    } catch (err) {
      notify("tr", "danger", "Erro ao carregar informações.");
    }
  }, [token]);

  useEffect(() => {
    getStores();
  }, [getStores]);

  return (
    <>
      <NotificationAlert ref={notificationAlert} />
      <div className="content">
        <Row md="12">
          <Col md="8">
            <h3>Estabelecimentos</h3>
          </Col>
          <Col md="4">
            <div style={{ float: "right" }}>
              <LinkStyled to={"/stores/new"}>
                <Button className="btn-round" color="success" outline>
                  <i className="fa fa-plus" />
                  Novo Estabelecimento
                </Button>
              </LinkStyled>
            </div>
          </Col>
        </Row>
        {loading ? (
          <div style={{ textAlign: "center" }}>
            <Spinner
              style={{ width: "3rem", height: "3rem" }}
              type="grow"
              color="danger"
            />
          </div>
        ) : (
          <div>
            <Row>
              <Col lg="4">
                <Row>
                  <Col lg="12" md="6" sm="6">
                    <CardDash
                      title="Clientes"
                      total={stores.length}
                      icon="nc-icon nc-shop text-danger"
                    ></CardDash>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <Input
                  name="search"
                  style={{ marginBottom: 20 }}
                  placeholder="Buscar"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </Col>
              <Col
                md="4"
                style={{ cursor: "pointer" }}
                onClick={() => setShowDeleted(!showDeleted)}
              >
                <Checkbox
                  checked={showDeleted}
                  onChange={(e) => setShowDeleted(e.target.checked)}
                />
                <span>Exibir deletados</span>
              </Col>
            </Row>
            <Row>
              {stores
                .filter((s) => {
                  if (!showDeleted && s.deleted_at !== null) {
                    return false;
                  }
                  return s.name.toLowerCase().includes(search.toLowerCase());
                })
                .map((store) => (
                  <Col md="6">
                    <CardStore store={store}></CardStore>
                  </Col>
                ))}
            </Row>
          </div>
        )}
      </div>
    </>
  );
}

export default Stores;
