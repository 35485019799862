import styled from "styled-components";
import { Link } from "react-router-dom";

export const LinkStyled = styled(Link)`
  &:hover {
    text-decoration: none;
  }
`;

export const ButtonLink = styled.button`
  background: none!important;
  border: none;
  padding: 0!important;
  /*optional*/
  /*input has OS specific font-family*/
  color: #51cbce;
  cursor: pointer;
`;