import React, { useRef, useState, useEffect, useCallback } from "react";
import Input from "../../components/Input";
import { Form } from "@unform/web";
import NotificationAlert from "react-notification-alert";

import history from "services/history";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Row,
  Col,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "reactstrap";
import AvatarInputGd from "../../components/AvatarInputGd";
import RowDivider from "components/RowDivider";
import api from "services/api";
import Select from "components/Form/SelectInput";
import { CheckboxContainer } from "components/Form/styles";
import CheckboxInput from "views/components/Checkbox";

function EditRestaurantGD(props) {
  const formRef = useRef(null);
  const notificationAlert = useRef(null);
  const { id } = props.match.params;
  const [restaurant, setRestaurant] = useState([]);
  const [model, setModel] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [storeToDelete, setStoreToDelete] = useState("");

  const toggleDeleteModal = () => {
    setDeleteModal(!deleteModal);
  };

  const token = localStorage.getItem("@clube:token");
  const models = [
    { value: "cashback", label: "Cashback" },
    { value: "points", label: "Pontos" },
  ];
  const initialModel = models.find((m) => {
    if (restaurant.settings) {
      return m.value === restaurant.settings[0]?.model;
    }
    return;
  });

  const initialData = {
    name: restaurant.name,
    email: restaurant.email,
    phone: restaurant.phone,
    model: initialModel,
    avatar_id: restaurant.avatar,
    cnpj: restaurant.cnpj,
    limit_register:
      restaurant.settings && restaurant.settings[0]?.limit_register,
    limit_reminder:
      restaurant.settings && restaurant.settings[0]?.limit_reminder,
    limit_expires: restaurant.settings && restaurant.settings[0]?.limit_expires,
    use_wpp: restaurant.settings && restaurant.settings[0]?.use_wpp,
  };

  const notify = (place, color, message) => {
    var options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>{message}</div>
        </div>
      ),
      type: color,
      icon: "now-ui-icons ui-1_bell-53",
      autoDismiss: 7,
    };
    console.log(notificationAlert);
    if (notificationAlert.current) {
      notificationAlert.current.notificationAlert(options);
    }
  };

  const getInfo = useCallback(async () => {
    try {
      const response = await api.get(`/store/edit/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setRestaurant(response.data);
    } catch (err) {
      notify("tr", "danger", "Erro ao carregar informações.");
    }
  }, [token]);

  async function updateProfile(data) {
    try {
      data.limit_expires =
        data.limit_expires.length > 0 ? parseInt(data.limit_expires) : null;
      data.limit_reminder =
        data.limit_reminder.length > 0 ? parseInt(data.limit_reminder) : null;
      data.limit_register =
        data.limit_register.length > 0 ? parseInt(data.limit_register) : null;

      const response = await api.put(
        `/store/edit/${id}`,
        { ...data, model: model.value },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setRestaurant(response.data);
      notify("tr", "success", "Atualizado com sucesso!");
    } catch (err) {
      notify("tr", "danger", "Erro ao atualizar.");
    }
  }

  const deleteStore = async (data) => {
    if (data.username === restaurant.name) {
      try {
        await api.delete(`/store/${id}`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        notify("tr", "success", "Apagado com sucesso!");
        setDeleteModal(false);
      } catch (err) {
        notify("tr", "danger", "Erro ao apagar.");
      }
    } else {
      notify("tr", "danger", "Username incorreto.");
    }
  };

  //test
  useEffect(() => {
    if (restaurant.settings) {
      setModel(models.find((m) => m.value === restaurant.settings[0]?.model));
    }
  }, [restaurant]);

  useEffect(() => {
    getInfo();
  }, [getInfo]);

  return (
    <>
      <NotificationAlert ref={notificationAlert} />
      <div className="content">
        <Row>
          <Col md="3">
            <Button color="default" onClick={() => history.goBack()}>
              <span className="btn-label">
                <i className="nc-icon nc-minimal-left" />
              </span>
              Voltar
            </Button>
          </Col>
        </Row>
        <Form ref={formRef} initialData={initialData} onSubmit={updateProfile}>
          <Row>
            <Col md="4">
              <div
                className="image"
                style={{ placeContent: "center", padding: 30 }}
              >
                <AvatarInputGd name="avatar_id" />
              </div>
            </Col>
            <Col md="8">
              <Card>
                <CardHeader>
                  <h5 className="title">Editar Perfil</h5>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col>
                      <FormGroup>
                        <label>Username</label>
                        <Input name="name" placeholder="Username" type="text" />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="8" className="pr-1">
                      <FormGroup>
                        <label htmlFor="exampleInputEmail1">E-mail</label>
                        <Input name="email" type="email" />
                      </FormGroup>
                    </Col>
                    <Col md="4" className="pl-1">
                      <FormGroup>
                        <label htmlFor="exampleInputEmail1">Telefone</label>
                        <Input
                          name="phone"
                          placeholder="(xx) xxxxx-xxxx"
                          type="phone"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Select
                        label="Modelo"
                        name="model"
                        options={models}
                        value={model}
                        onChange={(e) => setModel(e)}
                      />
                    </Col>
                  </Row>

                  {restaurant.plan === "banestes" && (
                    <>
                      <RowDivider title="Banestes" />
                      <Row>
                        <Col>
                          <p style={{ margin: 0 }}>
                            Créditos Banestes: R$
                            {(restaurant.banestes_credit / 45).toLocaleString(
                              "pt-BR",
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }
                            )}
                          </p>
                        </Col>
                      </Row>
                    </>
                  )}
                  <RowDivider title="Whatsapp" />
                  <Row>
                    <Col md="12">
                      <CheckboxInput
                        name="use_wpp"
                        label="Automação whatsapp ativa"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md="4">
                      <label>Registros</label>
                      <Input name="limit_register" type="number" />
                    </Col>
                    <Col md="4">
                      <label>Lembretes</label>
                      <Input name="limit_reminder" type="number" />
                    </Col>
                    <Col md="4">
                      <label>Validade</label>
                      <Input name="limit_expires" type="number" />
                    </Col>
                  </Row>

                  <Row style={{ paddingTop: 15 }}>
                    <Col md="4" className="ml-auto">
                      <Button color="success" type="submit">
                        Atualizar Perfil
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Form>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            color="danger"
            type="button"
            onClick={() => setDeleteModal(true)}
          >
            Apagar
          </Button>
        </div>

        <Modal isOpen={deleteModal} toggle={toggleDeleteModal}>
          <ModalHeader>Apagar clube</ModalHeader>
          <ModalBody>
            <p>Tem certeza que deseja apagar este clube?</p>
            <p>
              Para confirmar, digite o username do clube e clique em CONFIRMAR
            </p>
          </ModalBody>
          <ModalFooter style={{ display: "flex", flexDirection: "column" }}>
            <Form
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-around",
              }}
              onSubmit={deleteStore}
            >
              <Input
                name="username"
                placeholder="Username"
                value={storeToDelete}
                onChange={(e) => setStoreToDelete(e.target.value)}
              />
              <Button>Confirmar</Button>
            </Form>
          </ModalFooter>
        </Modal>
      </div>
    </>
  );
}

export default EditRestaurantGD;
