import React from "react";
import { AuthProvider } from "./context/AuthContext";
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "./routes/index";
import { createBrowserHistory } from "history";
const hist = createBrowserHistory();

function App() {
  return (
    <div>
      <Router history={hist}>
        <AuthProvider>
          <Routes />
        </AuthProvider>
      </Router>
    </div>
  );
}
//
//   <Switch>
//     <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
//     <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
//     <Redirect to="/auth/login" />
//   </Switch>
//

export default App;
