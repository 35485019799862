import styled from "styled-components";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Button,
  Row,
  Col,
} from "reactstrap";

export const LinkRestaurant = styled.a`
  &:hover {
    text-decoration: none;
  }
`;

export const CardStyled = styled(Card)`
  transition-duration: 0.2s;
  height: auto;

  &:hover {
    -webkit-transform: scale(1.05);
    -ms-transform: scale(1.05);
    transform: scale(1.05);
  }
`;

export const Legends = styled.div`
  display: flex;
  width: 90%;
  align-itens: center;
  text-align: center;
  justify-content: center;
  clear: both;
  position: relative;
`;

export const CategoryLegend = styled.div.attrs((props) => ({
  color: props.color,
}))`
  padding: 5px;
  display: flex;
  align-text: center;
  margin: auto 0;

  div {
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background-color: ${(props) => props.color};
    margin-right: 5px;
  }
`;
