import React, { useEffect, useRef } from "react";
import { useField } from "@unform/core";

import { InputContainer } from "./styles";

function Input({ name, label, ...rest }) {
  const inputRef = useRef(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "value",
    });
  }, [fieldName, inputRef, registerField]);

  return (
    <InputContainer>
      <label htmlFor={fieldName}>{label}</label>
      <input ref={inputRef} defaultValue={defaultValue} {...rest} />
      {error && <span>{error}</span>}
    </InputContainer>
  );
}

export default Input;
