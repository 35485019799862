import React, { createContext, useCallback, useState, useContext } from "react";
import api from "../services/api";
import apiGd from "../services/apiGd";

const AuthContext = createContext();

const AuthProvider: React.FC = ({ children }) => {
  const [data, setData] = useState(() => {
    const token = localStorage.getItem("@clube:token");
    const user = localStorage.getItem("@clube:user");

    if (token && user) {
      return { token, user: JSON.parse(user) };
    }

    return {};
  });

  const signIn = useCallback(async ({ email, password }) => {
    const response = await api.post("public/sessions/store", {
      email,
      password,
    });

    const { token, user } = response.data;

    if (user.admin) {
      localStorage.setItem("@clube:token", token);
      localStorage.setItem("@clube:user", JSON.stringify(user));

      setData({ token, user });
      return true;
    } else {
      return false;
    }
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem("@clube:token");
    localStorage.removeItem("@clube:user");

    setData({});
  });

  // const updateProfile = useCallback(async (props) => {
  //   try {
  //     const token = localStorage.getItem("@QRExpress:token");
  //     const profile = {
  //       fantasy_name: props.fantasy_name,
  //       email: props.email,
  //       phone: props.phone,
  //       avatar_id: props.avatar,
  //     };

  //     const response = await api.put("restaurants", profile, {
  //       headers: { Authorization: `Bearer ${token}` },
  //     });

  //     const user = response.data;
  //     localStorage.setItem("@QRExpress:user", JSON.stringify(user));

  //     setData({ token, user });
  //     console.log(data);
  //     return true;
  //   } catch (err) {
  //     return false;
  //   }
  // });

  return (
    <AuthContext.Provider
      value={{ user: data.user, signIn, signOut }} //, updatedProfile }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }

  return context;
}

export { AuthProvider, useAuth };
