import React, { useState, useEffect, useRef } from "react";
import { Form } from "@unform/web";
import history from "../../../services/history";
import Switch from "react-bootstrap-switch";

import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Label,
  FormGroup,
  Row,
  Col,
  FormText,
  Spinner,
  CustomInput,
} from "reactstrap";

import { Map, TileLayer, Marker } from "react-leaflet";
import Input from "../../components/Input";
import AvatarInputGd from "../../components/AvatarInputGd";
import api from "../../../services/api";
import NotificationAlert from "react-notification-alert";
import Select from "../../../components/Form/SelectInput";

export default function NewStore() {
  const notificationAlert = useRef(null);
  const buttonNew = useRef(null);

  const token = localStorage.getItem("@clube:token");
  const [loading, setLoading] = useState(false);
  const [hasBirthday, setHasBirthday] = useState(false);

  const notify = (place, color, message) => {
    var options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>{message}</div>
        </div>
      ),
      type: color,
      icon: "now-ui-icons ui-1_bell-53",
      autoDismiss: 7,
    };
    notificationAlert.current.notificationAlert(options);
  };

  async function handleSubmitNewStore(data) {
    try {
      setLoading(true);
      const profile = {
        name: data.name,
        email: data.email,
        password: data.password,
        phone: data.phone,
        whatsapp: data.phone,
        avatar_id: data.avatar,
        plan: data.plan,
        cep: data.cep,
        owner_name: data.owner_name,
        owner_phone: data.owner_phone,
        link: data.link,
        model: typeSelected,
        percent: data.percent,
        points: data.points,
        minimo: data.minimo,
        birthday_auto: hasBirthday,
        obs: data.obs,
      };

      const response = await api.post("store/create", profile, {
        headers: { Authorization: `Bearer ${token}` },
      });

      console.log(response);

      setLoading(false);
      notify("tr", "success", "Estabelecimento criado com sucesso!");
    } catch (err) {
      notify("tr", "danger", "Erro ao cadastrar estabelecimento.");
    }
  }

  let planOptions = [
    { value: "basic", label: "Básico" },
    { value: "premium", label: "Premium" },
  ];

  let type_options = [
    { value: "cashback", label: "Cashback" },
    { value: "points", label: "Pontos" },
  ];

  const [typeSelected, setTypeSelected] = useState("cashback");
  const [planSelected, setPlanSelected] = useState("premium");

  return (
    <>
      <NotificationAlert ref={notificationAlert} />
      <div className="content">
        <h2>Cadastro de Estabelecimentos</h2>
        <Row>
          <Col md="3">
            <Button color="default" onClick={() => history.goBack()}>
              <span className="btn-label">
                <i className="nc-icon nc-minimal-left" />
              </span>
              Voltar
            </Button>
          </Col>
        </Row>
        <Form className="form-horizontal" onSubmit={handleSubmitNewStore}>
          <Row>
            <Col md="4" sm="4">
              <CardTitle tag="h4">Logo</CardTitle>
              <AvatarInputGd name="avatar" />
            </Col>
            <Col md="8">
              <Card>
                <CardBody>
                  <Row>
                    <Label sm="2">Nome</Label>
                    <Col sm="10">
                      <FormGroup>
                        <Input type="text" name="name" />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Label sm="2">E-mail</Label>
                    <Col sm="10">
                      <FormGroup>
                        <Input type="email" name="email" />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Label sm="2">Senha</Label>
                    <Col sm="10">
                      <FormGroup>
                        <Input
                          type="password"
                          name="password"
                          autoComplete="off"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Label sm="2">Telefone</Label>
                    <Col sm="10">
                      <FormGroup>
                        <Input type="text" name="phone" />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Label sm="2">Cep</Label>
                    <Col sm="10">
                      <FormGroup>
                        <Input type="text" name="cep" />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Label sm="2">Link</Label>
                    <Col sm="10">
                      <FormGroup>
                        <Input type="text" name="link" />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Label sm="2">
                      <div style={{ paddingTop: 15 }}>Plano</div>
                    </Label>
                    <Col sm="10">
                      <Select
                        name="plan"
                        options={planOptions}
                        defaultValue={{ value: "premium", label: "Premium" }}
                        onChange={(e) => setPlanSelected(e.value)}
                      />
                    </Col>
                  </Row>
                  <Row style={{ paddingTop: 30 }}>
                    <Label sm="2">Nome do Proprietário</Label>
                    <Col sm="10">
                      <FormGroup>
                        <Input type="text" name="owner_name" />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Label sm="2">Telefone do Proprietário</Label>
                    <Col sm="10">
                      <FormGroup>
                        <Input type="text" name="owner_phone" />
                      </FormGroup>
                    </Col>
                  </Row>

                  {/* <Row style={{borderTop: '1px solid #CCCCCC', width: "90%", float: "right", marginRight: 10}}></Row> */}
                  <Row
                    style={{ borderTop: "1px solid #CCCCCC", marginTop: 15 }}
                  >
                    <Label sm="2">
                      <div style={{ paddingTop: 15 }}>Plano</div>
                    </Label>
                    <Col sm="10">
                      <Select
                        name="type"
                        options={type_options}
                        defaultValue={{ value: "cashback", label: "Cashback" }}
                        onChange={(e) => setTypeSelected(e.value)}
                      />
                    </Col>
                  </Row>

                  <Row style={{ marginLeft: 20 }}>
                    <label
                      style={{
                        margin: 20,
                        fontSize: 13,
                      }}
                    >
                      Escolha a porcentagem de cashback que seu cliente irá
                      ganhar a partir do valor gasto em seu estabelecimento.
                    </label>
                    {typeSelected === "cashback" ? (
                      <Col md="6">
                        <FormGroup>
                          <label>Taxa de Cashback (%)</label>
                          <Input name="percent" type="text" placeholder="%" />
                        </FormGroup>
                      </Col>
                    ) : (
                      <>
                        <Col md="6">
                          <FormGroup>
                            <label>
                              A Cada quantos reais completa um ponto:
                            </label>
                            <Input name="points" type="text" />
                          </FormGroup>
                        </Col>
                      </>
                    )}
                    <Col md="6">
                      <FormGroup>
                        <label>Resgate Mínimo</label>
                        <Input name="minimo" type="text" placeholder="R$" />
                      </FormGroup>
                    </Col>
                  </Row>
                  {/* {typeSelected === 'points' &&
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <label>R$1 equivale a quantos pontos: </label>
                        <Input
                          name="points_rescue"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  }  */}

                  {planSelected === "premium" && (
                    <Row style={{ marginTop: 20, marginLeft: 40 }}>
                      <h6 className="title">
                        <Switch
                          onColor="success"
                          offColor="success"
                          defaultValue={hasBirthday}
                          onChange={() => setHasBirthday(!hasBirthday)}
                        />{" "}
                        Aniversários
                      </h6>
                      <label
                        style={{
                          marginLeft: 20,
                          fontSize: 13,
                          marginTop: 4,
                        }}
                      >
                        Se ativado, os clientes receberão mensagem de
                        aniversário.
                      </label>
                    </Row>
                  )}

                  <Row style={{ marginTop: 20 }}>
                    <Label sm="2">Observações</Label>
                    <Col sm="10">
                      <FormGroup>
                        <Input type="text" name="obs" />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row style={{ paddingTop: 15 }}>
                    <Col md="3" className="ml-auto">
                      <Button color="success" type="submit">
                        {loading ? (
                          <span>
                            <Spinner size="sm" color="white" />
                          </span>
                        ) : (
                          <span>Cadastrar</span>
                        )}
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
}
