import React, { useState, useEffect, useCallback, useRef, useMemo } from "react";
import { format, startOfDay, endOfDay, subHours, startOfWeek, startOfMonth, addHours} from 'date-fns';
import { Form } from "@unform/web";

import {
  // Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  // Label,
  // FormGroup,
  // Input,
  // Table,
  Row,
  Col,
  Collapse,
  Modal,
  ModalBody,
  Table
  // UncontrolledTooltip,
} from "reactstrap";

import NotificationAlert from "react-notification-alert";
import { Input } from "@material-ui/core";
import TableHistoryOrders from './components/TableHistoryOrders';
import CardDash from "components/Cards/CardDash";

function Dashboard(){
  const formRef = useRef(null);
  const notificationAlert = useRef();
  
    return (
      <>
      <NotificationAlert ref={notificationAlert} />
        <div className="content">
          <Row>
            <Col md="5">
            <h2><i className="nc-icon nc-refresh-69" />  Histórico de Compras</h2>

            </Col>
            <Col md="7">
              <div style={{ float: "right" }}>
                <Button className="btn-round mr-auto" active color="info">
                  Hoje
                </Button>
                <Button className="btn-round mr-auto" color="info">
                  Essa semana
                </Button>
                <Button className="btn-round mr-auto" color="info">
                  Esse mês
                </Button>
                <Button className="btn-round mr-auto" color="info">
                  Selecionar Período
                </Button>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg="4" md="6" sm="6">
              <CardDash
                title="Clientes"
                total={5}
                icon="nc-icon nc-single-02 text-info"
              ></CardDash>
            </Col>
            <Col lg="4" md="6" sm="6">
              <CardDash
                title="Arrecadação"
                total="R$367,00"
                icon="nc-icon nc-money-coins text-success"
              ></CardDash>
            </Col>
            <Col lg="4" md="6" sm="6">
              <CardDash
                title="Clientes Novos"
                total="1"
                icon="nc-icon nc-bulb-63 text-danger"
              ></CardDash>
            </Col>
          </Row>

          <Row md="12">
            <Col md="12">           
             <TableHistoryOrders/>
            </Col>
          </Row>
        </div>
      </>
    );
}

export default Dashboard;
