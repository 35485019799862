import React from "react";
import { Switch } from "react-router-dom";
import Login from "../views/pages/Login";
import Dashboard from "../views/Dashboard.jsx";
// import Financial from "../views/Financial.jsx";
import Route from "./Route";
// import UserProfile from "views/pages/UserProfile.jsx";
import Stores from "views/pages/Stores/Stores";
import NewStore from "views/pages/Stores/NewStore";
import SmsPage from "views/pages/SmsPage";
import EditRestaurantGD from "views/pages/Stores/EditRestaurantGD";

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={Login} />

      <Route path="/stores/new" exact component={EditRestaurantGD} isPrivate />
      <Route path="/stores/:id" exact component={EditRestaurantGD} isPrivate />
      <Route path="/stores" exact component={Stores} isPrivate />
      <Route path="/sms" exact component={SmsPage} isPrivate />
    </Switch>
  );
}
