import React, {useState} from "react";

// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Label,
  FormGroup,
  Input,
  Table,
  Row,
  Modal,
  ModalBody,
  Col,
  UncontrolledTooltip
} from "reactstrap";

function TableHistoryOrders(){
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

    return (
      <>
        <Card>
            <CardHeader>
                <CardTitle tag="h4">Clientes</CardTitle>
            </CardHeader>
            <CardBody>
                <Table responsive>
                <thead className="text-primary">
                    <tr>
                    <th className="text-center">#</th>
                    <th>Nome</th>
                    <th>Telefone</th>
                    <th className="text-center">Valor da Compra</th>
                    <th className="text-right">Cashback</th>
                    <th className="text-right">Data / Hora</th>
                    <th className="text-right">Ações</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                    <td className="text-center">5</td>
                    <td>
                        <strong style={{color: 'green'}}>
                        Pedro Mastelo
                        </strong>
                    </td>
                    <td>(27) 98765-4321</td>
                    <td className="text-center">R$50,00</td>
                    <td className="text-right">R$5,00</td>
                    <td className="text-right">14/05/2021 - 17:34</td>
                    <td className="text-right">
                        <Button
                        className="btn-icon"
                        color="info"
                        id="tooltip264453216"
                        size="sm"
                        type="button"
                        onClick={toggle}
                        >
                        <i className="fa fa-user" />
                        </Button>{" "}
                    </td>
                    </tr>
                    <tr>
                    <td className="text-center">4</td>
                    <td>
                        <strong style={{color: 'green'}}>
                        Mateus Gomes
                        </strong>
                    </td>
                    <td>(27) 98765-4334</td>
                    <td className="text-center">R$85,00</td>
                    <td className="text-right">R$8,50</td>
                    <td className="text-right">14/05/2021 - 16:55</td>
                    <td className="text-right">
                        <Button
                        className="btn-icon"
                        color="info"
                        id="tooltip264453216"
                        size="sm"
                        type="button"
                        >
                        <i className="fa fa-user" />
                        </Button>{" "}
                    </td>
                    </tr><tr>
                    <td className="text-center">3</td>
                    <td>
                        <strong style={{color: 'green'}}>
                            Vinicius da Silva
                        </strong>
                    </td>
                    <td>(27) 98765-9876</td>
                    <td className="text-center">R$120,00</td>
                    <td className="text-right">R$12,00</td>
                    <td className="text-right">14/05/2021 - 15:26</td>
                    <td className="text-right">
                        <Button
                        className="btn-icon"
                        color="info"
                        id="tooltip264453216"
                        size="sm"
                        type="button"
                        >
                        <i className="fa fa-user" />
                        </Button>{" "}
                    </td>
                    </tr>
                    <tr>
                    <td className="text-center">2</td>
                    <td>
                        <strong style={{color: 'red'}}>
                            Cliente Sem Cadastro
                        </strong>
                    </td>
                    <td>(27) 98765-7896</td>
                    <td className="text-center">R$89,00</td>
                    <td className="text-right">R$8,90</td>
                    <td className="text-right">14/05/2021 - 15:14</td>
                    <td className="text-right">
                        <Button
                        className="btn-icon"
                        color="info"
                        id="tooltip264453216"
                        size="sm"
                        type="button"
                        >
                        <i className="fa fa-user" />
                        </Button>{" "}
                    </td>
                    </tr>
                    <tr>
                    <td className="text-center">1</td>
                    <td>
                        <strong style={{color: 'green'}}>
                        André Secchin
                        </strong>
                    </td>
                    <td>(27) 98765-7777</td>
                    <td className="text-center">R$23,00</td>
                    <td className="text-right">R$2,30</td>
                    <td className="text-right">14/05/2021 - 14:34</td>
                    <td className="text-right">
                        <Button
                        className="btn-icon"
                        color="info"
                        id="tooltip264453216"
                        size="sm"
                        type="button"
                        >
                        <i className="fa fa-user" />
                        </Button>{" "}
                    </td>
                    </tr>
                </tbody>
                </Table>
            </CardBody>
            </Card>

            <Modal isOpen={modal} toggle={toggle} size='lg'>
            <ModalBody>
                <Row>
                    <Col md="9">
                    <h3>Pedro Mastelo</h3>
                    </Col>
                    <Col md="3" className="ml-auto" >
                    <Button color="success">
                        Enviar Promoção
                    </Button>
                    </Col>
                </Row>
                
                <Row style={{paddingTop: 20}}>
                    <Col md="4">
                    <strong>Telefone: </strong> (27) 99922-8726
                    </Col>
                    <Col md="3">
                    <strong>Sexo: </strong> Masculino
                    </Col>
                    <Col md="5">
                    <strong>Data de Nascimento: </strong> 14/05/1989                    </Col>
                </Row>
                <Row style={{paddingTop: 10}}>
                    <Col md="12">
                    <strong>Cashback Disponível:</strong> R$4,50
                    </Col>
                </Row>
                <Row style={{paddingTop: 10}}>
                    <Col md="12">
                    <strong>Produto Favorito: </strong> Dipirona
                    </Col>
                </Row>
                <Row style={{paddingTop: 10}}>
                    <Col>
                    <strong>Gasto em seu estabelecimento: </strong> R$267,98  
                    </Col>
                </Row>
                <Row style={{paddingTop: 10 }}>
                    <Col>
                    <strong>Comprou em seu estabelecimento: </strong> 3 vezes.  
                    </Col>
                </Row>
                <Row style={{paddingTop: 10, paddingBottom: 20}}>
                    <Col>
                    <strong>Próximo envio de SMS: </strong> em 24 dias.  
                    </Col>
                </Row>

                <h5><i className="nc-icon nc-user-run" />  Visitas</h5>
              <Table>
                <thead>
                  <tr>
                    <th>Valor Gasto</th>
                    <th>Produtos</th>
                    <th className="text-center">Data</th>
                    <th className="text-right">Dias</th>
                  </tr>
                </thead>
                <tbody>
                    <tr>
                      <td>R$100,00</td>
                      <td>1</td>
                      <td className="text-center">13/05/2021 - 13:45</td>
                      <td className="text-right">há 1 dia</td>
                    </tr>
                    <tr>
                      <td>R$110,00</td>
                      <td>3</td>
                      <td className="text-center">08/05/2021 - 11:54</td>
                      <td className="text-right">há 6 dias</td>
                    </tr>
                    <tr>
                      <td>R$50,00</td>
                      <td>2</td>
                      <td className="text-center">01/05/2021 - 19:33</td>
                      <td className="text-right">há 13 dias</td>
                    </tr>
                    <tr>
                      <td>R$17,98</td>
                      <td>1</td>
                      <td className="text-center">14/04/2021 - 18:22</td>
                      <td className="text-right">há 30 dias</td>
                    </tr>
                </tbody>
              </Table>

              <h5><i className="nc-icon nc-bag-16" /> Produtos</h5>
              <Table>
                <thead>
                  <tr>
                    <th>Produto</th>
                    <th>Quantidade de Compras</th>
                    <th className="text-center">Data da Última Compra</th>
                    <th className="text-right">Dias</th>
                  </tr>
                </thead>
                <tbody>
                    <tr>
                      <td>Dipirona</td>
                      <td>3</td>
                      <td className="text-center">13/05/2021 - 13:45</td>
                      <td className="text-right">há 1 dia</td>
                    </tr>
                    <tr>
                      <td>Camisinha</td>
                      <td>2</td>
                      <td className="text-center">08/05/2021 - 11:54</td>
                      <td className="text-right">há 6 dias</td>
                    </tr>
                    <tr>
                      <td>Shampoo</td>
                      <td>2</td>
                      <td className="text-center">01/05/2021 - 19:33</td>
                      <td className="text-right">há 13 dias</td>
                    </tr>
                </tbody>
              </Table>
            </ModalBody>
          </Modal>
        </>
    );
}

export default TableHistoryOrders;