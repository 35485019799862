import React, { useRef, useCallback, useContext } from "react";
import * as Yup from "yup";
import Input from "../../components/Input";
import { Form } from "@unform/web";
import { useAuth } from "../../../context/AuthContext";
import logo from "assets/img/logotakeat.png";
import NotificationAlert from "react-notification-alert";
import {
  Button,
  CardHeader,
  CardBody,
  CardFooter,
  Label,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
  Row,
  Card,
  Container,
} from "reactstrap";

import { StyledForm } from "./styles.js";

const Login: React.FC = () => {
  const notificationAlert = useRef(null);

  const notify = (place, color, message) => {
    var options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>{message}</div>
        </div>
      ),
      type: color,
      icon: "now-ui-icons ui-1_bell-53",
      autoDismiss: 7,
    };
    notificationAlert.current.notificationAlert(options);
  };

  function componentDidMount() {
    document.body.classList.toggle("login-page");
  }
  function componentWillUnmount() {
    document.body.classList.toggle("login-page");
  }

  const formRef = useRef(null);
  const { signIn, user } = useAuth();

  const handleSubmit = useCallback(
    async (data) => {
      try {
        const response = await signIn({
          email: data.email,
          password: data.password,
        });

        if(response === false){
          notify("tr", "danger", "Entre com uma conta de administrador.");
        }

      } catch (err) {
        notify("tr", "danger", "Falha na autenticação, verifique seus dados.");
      }
    },
    [signIn]
  );

  return (
    <>
      <NotificationAlert ref={notificationAlert} />
      <div className="login-page">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" lg="4" md="6">
              <Card className="card-login">
                <CardHeader>
                  <CardHeader>
                    {/* <div style={{ textAlign: "center" }}>
                      <img
                        src={logo}
                        alt="Logo takeat Dashboard"
                        style={{ width: 200, height: 54 }}
                      />
                    </div> */}

                    <h3 className="header text-center">Login</h3>
                  </CardHeader>
                </CardHeader>
                <Form ref={formRef} onSubmit={handleSubmit}>
                  <CardBody>
                    <Input
                      name="email"
                      label="E-mail"
                      type="email"
                      placeholder="Seu e-mail"
                    />

                    <Input
                      name="password"
                      type="password"
                      label="Senha"
                      placeholder="Sua senha"
                    />
                  </CardBody>
                  <CardFooter>
                    <Button
                      block
                      className="btn-round mb-3"
                      color="danger"
                      type="submit"
                    >
                      Login
                    </Button>
                  </CardFooter>
                </Form>
              </Card>
            </Col>
          </Row>
        </Container>

        <div
          className="full-page-background"
          style={{
            backgroundImage: `url(${require("assets/img/bg/backgroundLogin.webp")})`,
          }}
        />
      </div>
    </>
  );
};

export default Login;
