import React, { useState, useRef, useCallback } from "react";
import NotificationAlert from "react-notification-alert";

import { Button, Row, Col, Card, Input, Modal, ModalBody } from "reactstrap";

import ReactInputMask from "react-input-mask";

import { format, addHours } from "date-fns";

import api from "../../services/api";
import { FaPencilAlt } from "react-icons/fa";
import { Form } from "@unform/web";
function SmsPage() {
  const notificationAlert = useRef(null);

  const notify = (place, color, message) => {
    var options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>{message}</div>
        </div>
      ),
      type: color,
      icon: "now-ui-icons ui-1_bell-53",
      autoDismiss: 7,
    };
    notificationAlert.current.notificationAlert(options);
  };

  const [isOpenPhone, setIsOpenPhone] = useState(false);
  function togglePhone() {
    setIsOpenPhone(!isOpenPhone);
  }

  const [isOpenBirthday, setIsOpenBirthday] = useState(false);
  function toggleBirthday() {
    setIsOpenBirthday(!isOpenBirthday);
  }

  const [phone, setPhone] = useState("");
  const [phoneEdit, setPhoneEdit] = useState("");
  const [birthdayEdit, setBirthdayEdit] = useState("");

  const [client, setClient] = useState(null);

  const token = localStorage.getItem("@clube:token");

  const getClient = useCallback(async () => {
    if (phone !== "" && phone[14] !== "_") {
      try {
        const thisPhone = phone.replace(" ", "").replace("-", "");
        const response = await api.get(`/admin/client/${thisPhone}`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        setClient(response.data);

        if (response.data === null) {
          notify("tr", "danger", "Cliente não cadastrado.");
        }
      } catch (err) {
        notify("tr", "danger", "Erro ao carregar informações.");
      }
    } else {
      notify("tr", "danger", "Digite um telefone válido.");
    }
  }, [token, phone]);

  const updatePhone = useCallback(async () => {
    if (phoneEdit !== "" && phoneEdit[14] !== "_") {
      if (client.isClient) {
        try {
          const newPhone = phoneEdit.replace(" ", "").replace("-", "");
          const response = await api.put(
            `/admin/client/${client.client.id}`,
            {
              phone: newPhone,
            },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );

          setClient({
            isClient: true,
            client: response.data,
          });

          togglePhone();

          notify("tr", "success", response?.data?.message || "Editado");
        } catch (err) {
          notify(
            "tr",
            "danger",
            err?.response?.data?.error || "Erro ao carregar informações."
          );
        }
      } else {
        try {
          const newPhone = phoneEdit.replace(" ", "").replace("-", "");
          const response = await api.put(
            `/admin/client/purchases/phone`,
            {
              old_phone: client.phone,
              new_phone: newPhone,
            },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );

          setClient({
            isClient: false,
            phone: newPhone,
            purchases: client.purchases,
          });

          togglePhone();

          notify("tr", "success", response?.data?.message || "Editado");
        } catch (err) {
          notify(
            "tr",
            "danger",
            err?.response?.data?.error || "Erro ao carregar informações."
          );
        }
      }
    } else {
      notify("tr", "danger", "Digite um número válido.");
    }
  }, [token, phone, client, phoneEdit, togglePhone]);

  const updateBirthday = useCallback(async () => {
    try {
      const date = new Date(birthdayEdit);

      if (!date) {
        notify("tr", "danger", "Insira uma data válida");
        return;
      }

      const newBirthday = addHours(date, 12);

      const response = await api.put(
        `/admin/client/${client.client.id}`,
        {
          birthday: newBirthday,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      setClient({
        isClient: true,
        client: response.data,
      });

      toggleBirthday();

      notify("tr", "success", response?.data?.message || "Editado");
    } catch (err) {
      notify(
        "tr",
        "danger",
        err?.response?.data?.error || "Erro ao carregar informações."
      );
    }
  }, [token, client, birthdayEdit, toggleBirthday]);

  return (
    <>
      <NotificationAlert ref={notificationAlert} />
      <div className="content">
        <Row md="12">
          <Col md="8">
            <h3>Clientes</h3>
          </Col>
        </Row>

        <Row>
          <Col md="10">
            <ReactInputMask
              mask="(99) 99999-9999"
              onChange={(e) => setPhone(e.target.value)}
            >
              {() => (
                <Input
                  name="search"
                  label="Número do cliente"
                  type="text"
                  style={{ padding: 15, fontSize: 18 }}
                />
              )}
            </ReactInputMask>
          </Col>
          <Col md="2">
            <Button color="success" onClick={getClient}>
              Pesquisar
            </Button>
          </Col>
        </Row>

        {client && !client.isClient && client.purchases <= 0 && (
          <p>Esse telefone não está em nossa base de dados</p>
        )}

        {client &&
          (client.isClient ? (
            <Card style={{ padding: 20, marginTop: 20 }}>
              <Row>
                <Col md="3" style={{ color: "green" }}>
                  <strong>Cliente cadastrado</strong>
                </Col>
                <Col md="3">
                  <strong>Nome:</strong> {client.client.name}
                </Col>
                <Col md="3">
                  <strong>Telefone:</strong> {client.client.phone}
                  <FaPencilAlt
                    onClick={(e) => togglePhone()}
                    style={{
                      color: "#0dcaf0",
                      marginLeft: 5,
                      cursor: "pointer",
                    }}
                  />
                </Col>
                <Col md="3">
                  <strong>Aniversário:</strong>{" "}
                  {client.client.birthday &&
                    format(new Date(client.client.birthday), "dd/MM/yyyy")}
                  <FaPencilAlt
                    onClick={(e) => toggleBirthday()}
                    style={{
                      color: "#0dcaf0",
                      marginLeft: 5,
                      cursor: "pointer",
                    }}
                  />
                </Col>
              </Row>
            </Card>
          ) : (
            client.purchases > 0 && (
              <Card style={{ padding: 20, marginTop: 20 }}>
                <Row>
                  <Col md="3" style={{ color: "red" }}>
                    <strong>Cliente não cadastrado</strong>
                  </Col>
                  <Col md="3">
                    <strong>Compras:</strong> {client.purchases}
                  </Col>
                  <Col md="3">
                    <strong>Telefone:</strong> {client.phone}
                    {client.purchases > 0 && (
                      <FaPencilAlt
                        onClick={(e) => togglePhone()}
                        style={{
                          color: "#0dcaf0",
                          marginLeft: 5,
                          cursor: "pointer",
                        }}
                      />
                    )}
                  </Col>
                </Row>
              </Card>
            )
          ))}

        <Modal isOpen={isOpenPhone} toggle={togglePhone}>
          <ModalBody>
            <Row>
              <Col md="9">
                <p>Editar número de telefone</p>
                <ReactInputMask
                  mask="(99) 99999-9999"
                  onChange={(e) => setPhoneEdit(e.target.value)}
                >
                  {() => (
                    <Input
                      name="phone_edit"
                      label="Novo número"
                      placeholder="Novo número"
                      type="text"
                      style={{ padding: 12, fontSize: 16 }}
                    />
                  )}
                </ReactInputMask>

                {client?.isClient === false && (
                  <p style={{ marginTop: 5 }}>
                    As compras feitas com o {client?.phone} serão atualizadas
                    pro novo número
                  </p>
                )}
              </Col>
              <Col md="3" style={{ marginTop: 30 }}>
                <Button color="success" onClick={updatePhone}>
                  Salvar
                </Button>
              </Col>
            </Row>
          </ModalBody>
        </Modal>

        <Modal isOpen={isOpenBirthday} toggle={toggleBirthday}>
          <ModalBody>
            <Form>
              <Row>
                <Col md="9">
                  <p>Editar data de nascimento</p>
                  <Input
                    name="new_birthday"
                    type="date"
                    onChange={(e) => setBirthdayEdit(e.target.value)}
                    style={{ padding: 12, fontSize: 16 }}
                  />
                </Col>
                <Col md="3" style={{ marginTop: 30 }}>
                  <Button color="success" onClick={updateBirthday}>
                    Salvar
                  </Button>
                </Col>
              </Row>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    </>
  );
}

export default SmsPage;
