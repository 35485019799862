import e from "cors";
import React from "react";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Button,
  Row,
  Col,
} from "reactstrap";
import { LinkRestaurant, CardStyled } from "./styles";

export default function CardStore({ store }) {
  const data = new Date(store.createdAt);
  const image = store.avatar ? store.avatar.url : "";

  const plans = {
    basic: "Básico",
    premium: "Premium",
    banestes: "Banestes",
  };

  return (
    <CardStyled>
      <CardBody>
        <Row>
          <Col md="3" style={{ margin: "auto" }}>
            {image !== "" ? <img alt="..." src={image} /> : <p>Sem foto</p>}
          </Col>
          <Col md="9">
            <NavLink to={{ pathname: `stores/${store.id}` }}>
              <h6>{store.name}</h6>
            </NavLink>
            <p>{store.email}</p>

            <p style={{ color: "green" }}>Plano {plans[store.plan]}</p>
            <p
              style={{
                border: "1px solid black",
                width: "fit-content",
                padding: "5px",
                borderRadius: "5px",
                cursor: "pointer",
              }}
              onClick={() => {
                navigator.clipboard.writeText(store.token);
              }}
            >
              Token: {store.token}
            </p>
            <a
              style={{ color: "blue" }}
              href={`https://app.takeat.club/${store.key}`}
            >
              https://app.takeat.club/{store.key}
            </a>
            <p>
              Cliente desde {data.getUTCMonth() + 1 < 10 && "0"}
              {data.getUTCMonth() + 1}/{data.getFullYear()}
            </p>
          </Col>
        </Row>
      </CardBody>
    </CardStyled>
  );
}
