import React from "react";

import { Row, Col } from "reactstrap";

function RowDivider({ title }) {
  return (
    <Row style={{ margin: "5px 0px" }}>
      <Col sm="4" style={{ display: "flex", "align-items": "center" }}>
        <hr style={{ margin: "0px 0px 0px 5px", width: "100%" }} />
      </Col>

      <Col
        sm="4"
        style={{ "font-size": "0.8rem", color: "grey", "text-align": "center" }}
      >
        {title}
      </Col>

      <Col sm="4" style={{ display: "flex", "align-items": "center" }}>
        <hr style={{ margin: "0px 5px 0px 0px", width: "100%" }} />
      </Col>
    </Row>
  );
}

export default RowDivider;
