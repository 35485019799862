import styled from "styled-components";
import { Form } from "@unform/web";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 500px;

  img {
    max-height: 100px;
  }
`;

const Card = styled.div`
  max-width: 500px;
  box-shadow: 0 0 5px 3px rgba(0, 0, 0, 0.2);
  padding: 20px;
  margin-top: 40px;
  background-color: #fff;
  text-align: center;
  border-radius: 10px;

  h1 {
    font-size: 24px;
    color: black;
  }
`;
const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px 0;

  input {
    /* background: rgba(0, 0, 0, 0.03); */
    min-width: 300px;
    border-radius: 4px;
    border: 2px solid #ddd;
    height: 44px;
    padding: 20px;
    color: black;
    margin: 0 0 10px;
  }

  button {
    margin-top: 20px;
    width: 100%;
    height: 44px;
    border: none;
    border-radius: 8px;
    background: linear-gradient(90deg, red, blue);
    color: #fff;
    font-weight: bold;
    font-size: 18px;
  }
`;

export { Card, Container, StyledForm };
